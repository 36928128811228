import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../Loading';

/* eslint-disable react/display-name */
const ProtectedRoute = ({ component, ...args }) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => (
        <Loading message="Redirecting to sign in with Twitch..." />
      ),
      loginOptions: {},
    })}
    {...args}
  />
);

export default ProtectedRoute;
