const BACKEND_URL = process.env.REACT_APP_BACKEND_URL ?? window.location.host;
const prefix = process.env.NODE_ENV === 'development' ? 'http' : 'https';
export const API_BASE_URL = `${prefix}://${BACKEND_URL}/api`;

export const getTypes = (identifier) => ({
  INIT: `${identifier}__INIT`,
  SUCCESS: `${identifier}__SUCCESS`,
  ERROR: `${identifier}__ERROR`,
});

const defaultState = {
  isLoading: false,
  lastRefreshed: null,
  data: null,
  error: false,
  errorMessage: null,
};

export const getReducers = (identifier) => {
  const types = getTypes(identifier);

  return (state = defaultState, action) => {
    switch (action.type) {
      case types.INIT:
        return {
          ...state,
          isLoading: true,
        };
      case types.SUCCESS:
        return {
          ...state,
          isLoading: false,
          data: action.payload,
          error: false,
          errorMessage: null,
          lastRefreshed: Date.now(),
        };
      case types.ERROR:
        return {
          ...state,
          isLoading: false,
          data: null,
          error: true,
          errorMessage: action.payload,
          lastRefreshed: null,
        };
      default:
        return state;
    }
  };
};

/**
 * Helper function for creating redux actions.
 * the load function is for making async request, starts by dispatching INIT to show app is loading
 * then after loading/putting data it will dispatch SUCCESS or ERROR
 * TODO: add param for optional error message to show.
 * @param {*} identifier -- the redux type identifier, i.e. USER_GET would make USER_GET_INIT etc
 * @param {*} endpoint -- endpoint to reach for load action
 * @param {*} fetchOptionFunction -- fn(params) => a fetch options object
 * @returns void
 */
export const getActions = (
  identifier,
  endpoint,
  fetchOptionFunction,
  followUpDispatch,
  followUpResponse
) => {
  const types = getTypes(identifier);
  return {
    load:
      (params = {}, awaitedAccessTokenFunction) =>
      async (dispatch, getState) => {
        // initialize/set isLoading status
        dispatch({
          type: types.INIT,
        });

        const fetchOptions = fetchOptionFunction?.(params) ?? {};

        // todo: getState() and see if lastRefreshed is recent or not & prevent too many round trips

        try {
          const token = await awaitedAccessTokenFunction({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          });

          const fetchParams = {
            method: fetchOptions.method ?? 'GET',
            ...fetchOptions,
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              ...fetchOptions?.headers,
            },
          };

          const response = await fetch(
            `${API_BASE_URL}/${endpoint(params)}`,
            fetchParams
          );
          const textResponse = await response.text();
          if (response?.status === 200) {
            // do json parse of the text response instead of the json() helper
            const jsonResponse = textResponse ? JSON.parse(textResponse) : {};
            dispatch({
              type: types.SUCCESS,
              payload: jsonResponse,
            });
            // now do a followup dispatch if it exists
            if (followUpDispatch) {
              dispatch(followUpDispatch(params, awaitedAccessTokenFunction));
            }
            if (followUpResponse) {
              dispatch(followUpResponse(jsonResponse));
            }
          } else {
            dispatch({
              type: types.ERROR,
              payload: `${textResponse}: Failed performing ${identifier}`,
            });
          }
        } catch (e) {
          console.error(`error in ${identifier} load action`, e);
          dispatch({
            type: types.ERROR,
            payload: e,
          });
        }
      },
    update: (typeName, payload) => (dispatch) => {
      dispatch({
        type: typeName.SUCCESS,
        payload,
      });
    },
  };
};
