export const CF_URL = 'https://d34dx5b6qkv1nr.cloudfront.net';

export const GA_MEASUREMENT_ID = 'G-8N1FBMY631';

export const NONCE = 'youwillneverguessthis';

export const PAYPAL_INITIAL_OPTIONS = {
  'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: 'USD',
  intent: 'capture',
};

export const DISCORD_URL = 'https://discord.gg/KEmUzbuaSb';
export const TWITTER_URL = 'https://twitter.com/rngroyalelbs';

export const ADMIN_ALLOW_LIST = ['friendshrimps', 'louloul', 'dearlola1'];
