import { getActions, getReducers, getTypes } from '../initializers';
import { actions as getUserActions, types as getUserTypes } from './get';

const identifier = 'USER_EQUIP_SKIN';

export const getUserEquipSkinState = (state) => state.user?.userEquipSkin;

const getFetchOptions = (params) => {
  const { id, skinId } = params;
  return {
    method: 'PUT',
    body: JSON.stringify({
      id,
      skinId,
    }),
  };
};

export const types = getTypes(identifier);
const endpoint = (params) => `users/equipSkin/${params.id}`;
export const actions = getActions(
  identifier,
  (params) => endpoint(params),
  (params) => getFetchOptions(params),
  undefined,
  (payload) => getUserActions.update(getUserTypes, payload)
);

const reducer = getReducers(identifier);

export default reducer;
