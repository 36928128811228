import { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import ProtectedRoute from '../auth/ProtectedRoute';
import './View.css';

// lazy imports for smaller bundle size
const Home = lazy(() => import('../../views/Home'));
const Admin = lazy(() => import('../../views/Admin'));
const Profile = lazy(() => import('../../views/Profile'));
const HiScores = lazy(() => import('../../views/HiScores'));
const Roadmap = lazy(() => import('../../views/Roadmap'));
const NotFound = lazy(() => import('../../views/NotFound'));
const WebBuild = lazy(() => import('../../views/WebBuild'));

/**
 * The generic View component will render a specific view per route.
 * @returns a View containing a component
 */
const View = () => (
  <div className="view">
    <Switch>
      <Route path="/" exact component={Home} />
      <ProtectedRoute path="/profile" component={Profile} />
      <ProtectedRoute path="/hiscores" component={HiScores} />
      <ProtectedRoute path="/roadmap" component={Roadmap} />
      <ProtectedRoute path="/web-build" component={WebBuild} />
      <ProtectedRoute path="/admin" component={Admin} />
      <Route path="/" component={NotFound} />
    </Switch>
  </div>
);

export default View;
