import { getActions, getReducers, getTypes } from '../initializers';

const identifier = 'CLIENT_VERSION_GET_LATEST';

export const getClientVersionLatestState = (state) =>
  state.clientVersion?.latest;

export const types = getTypes(identifier);
export const actions = getActions(identifier, () => 'latest-version');
const reducer = getReducers(identifier);

export default reducer;
