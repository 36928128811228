import { NavLink } from 'react-router-dom';

const NavLinks = ({ getIsActivePath }) => (
  <div className="navbar__items">
    <NavLink to="/" exact className={`navbar__link ${getIsActivePath('/')}`}>
      Home
    </NavLink>
    <NavLink
      to="/hiscores"
      exact
      className={`navbar__link ${getIsActivePath('/hiscores')}`}
    >
      Hiscores
    </NavLink>
    <NavLink
      to="/profile"
      exact
      className={`navbar__link ${getIsActivePath('/profile')}`}
    >
      Profile
    </NavLink>
  </div>
);

export default NavLinks;
