import DiscordIcon from '../Svg/DiscordIcon';
import { DISCORD_URL, TWITTER_URL } from '../../utilities/constants';
import './SocialLinks.css';
import XSocial from '../Svg/XSocialIcon';

const SocialLinks = () => (
  <ul className="social-links">
    <li className="social-links__list-item">
      <a
        href={DISCORD_URL}
        title="Join the Discord"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="d-flex flex-column align-items-center">
          <DiscordIcon />
          <div className="social-links__list-item__text">Join the Discord</div>
        </div>
      </a>
    </li>
    <li className="social-links__list-item">
      <a
        href={TWITTER_URL}
        title="Follow on X (Twitter)"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="d-flex flex-column align-items-center">
          <XSocial size={32} />
          <div className="social-links__list-item__text">Follow on X</div>
        </div>
      </a>
    </li>
  </ul>
);

export default SocialLinks;
