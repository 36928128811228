import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { ParallaxProvider } from 'react-scroll-parallax';
import reportWebVitals from './reportWebVitals';
import Auth0ProviderWithHistory from './components/auth/Auth0ProviderWithHistory';
import store from './ducks/store';
import { PAYPAL_INITIAL_OPTIONS } from './utilities/constants';
import './index.css';

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PayPalScriptProvider options={PAYPAL_INITIAL_OPTIONS}>
        <ParallaxProvider>
          <Auth0ProviderWithHistory />
        </ParallaxProvider>
      </PayPalScriptProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
