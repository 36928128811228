import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { User as UserIcon } from 'feather-icons-react';
import {
  actions as getUserGetActions,
  getUserGetState,
} from '../../ducks/user/get';
import { getAuthUserId } from '../../utilities/authUtils';
import useTwitchUsernameFromAuth0 from '../../hooks/useTwitchUsernameFromAuth0';
import Troutcoin from '../Troutcoin';

const NavBarProfile = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const twitchUsername = useTwitchUsernameFromAuth0();
  const dispatch = useDispatch();

  const userData = useSelector((state) => getUserGetState(state)?.data);

  useEffect(() => {
    if (!userData) {
      const authUserId = getAuthUserId(user);
      // on page load, get user by id from api by dispatching load action
      dispatch(
        getUserGetActions.load({ id: authUserId }, getAccessTokenSilently)
      );
    }
  }, [dispatch, userData, getAccessTokenSilently, user]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="nav-bar__profile">
      <Link
        to="/profile"
        className="nav-bar__profile__profile-link"
        title="Profile"
      >
        <UserIcon />
        <span className="nav-bar__profile__profile-link__text">
          {twitchUsername}
        </span>
      </Link>
      <div className="nav-bar__profile__trout-coins">
        <div className="nav-bar__profile__trout-coins__icon">
          <Troutcoin size={16} />
        </div>
        <div className="nav-bar__profile__trout-coins__count">
          {userData?.troutCoins || 0}
        </div>
      </div>
    </div>
  );
};

export default NavBarProfile;
