import { getActions, getReducers, getTypes } from '../initializers';

const identifier = 'GAMES_LIST';

export const getGamesListState = (state) => state.hiscores?.gamesList;

export const types = getTypes(identifier);
export const actions = getActions(identifier, () => 'gameResult/gamesPlayed');
const reducer = getReducers(identifier);

export default reducer;
