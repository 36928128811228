import { getActions, getReducers, getTypes } from '../initializers';

const identifier = 'PLAYERS_LIST';

export const getPlayersListState = (state) => state.hiscores?.playersList;

export const types = getTypes(identifier);
export const actions = getActions(identifier, () => 'users/list');
const reducer = getReducers(identifier);

export default reducer;
