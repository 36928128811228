import { getActions, getReducers, getTypes } from '../initializers';

// updating user state
const identifier = 'USER_UPDATE';

export const getUserUpdateState = (state) => state.user?.userUpdate;

const fetchOptions = (params) => {
  const { id, name, messageData } = params;
  return {
    method: 'PUT',
    body: JSON.stringify({
      id,
      name,
      messageData,
    }),
  };
};

export const types = getTypes(identifier);
export const actions = getActions(
  identifier,
  (params) => `users/update/${params.id}`,
  (params) => fetchOptions(params)
);
const reducer = getReducers(identifier);

export default reducer;
