const FishIcon = ({ size }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 279.135 279.135"
    width={size ?? 25}
    height={size ?? 25}
    fill="currentColor"
  >
    <path
      d="M279.035,7.244c-0.178-3.872-3.276-6.971-7.147-7.148c-0.074-0.003-1.843-0.083-4.943-0.083
	c-20.358,0-87.217,3.247-134.498,42.847l-37.49-5.632c-0.959-0.144-1.936-0.217-2.901-0.217c-8.961,0-16.724,6.08-18.877,14.787
	L62.112,96.532c-1.284,5.188-0.42,10.534,2.431,15.054c2.852,4.52,7.305,7.602,12.538,8.678l6.102,1.255
	c-4.301,14.13-6.9,27.74-8.46,39.533l-62.349,14.042c-6.177,1.392-10.794,6.111-12.048,12.318s1.168,12.35,6.32,16.029
	c0.622,0.445,1.309,0.792,2.036,1.028l38.563,12.565c7.014,2.285,12.564,7.836,14.851,14.85l12.564,38.563
	c0.236,0.728,0.584,1.414,1.028,2.037c3.014,4.218,7.696,6.638,12.848,6.638c7.485,0,13.86-5.085,15.5-12.367l14.057-62.412
	c9.78-1.299,20.767-3.307,32.236-6.379l-0.882,18.834c-0.262,5.532,3.475,10.405,8.883,11.588l14.654,3.209
	c0.798,0.175,1.615,0.264,2.429,0.264c6.014,0,10.975-4.708,11.294-10.718l2-37.542c13.99-7.006,27.535-16.12,39.385-27.969
	C282.684,101.038,279.209,11.048,279.035,7.244z M225.563,47.494l-1.221,0.729c-2.409,1.438-5.059,2.121-7.673,2.121
	c-5.108,0-10.087-2.609-12.896-7.314c-4.245-7.114-1.92-16.322,5.193-20.568l1.221-0.729c7.116-4.247,16.322-1.919,20.568,5.193
	C235.001,34.04,232.676,43.248,225.563,47.494z"
    />
  </svg>
);

export default FishIcon;
