export const getNotificationState = (state) => state?.notifications;

export const types = {
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  HIDE_NOTIFICATION: 'HIDE_NOTIFICATION',
};

export const actions = {
  show:
    ({ messageText, messageType }) =>
    (dispatch) => {
      dispatch({
        type: types.SHOW_NOTIFICATION,
        payload: {
          messageText,
          messageType,
        },
      });
    },
  hide: () => (dispatch) => {
    dispatch({
      type: types.HIDE_NOTIFICATION,
    });
  },
  error:
    ({ message, messageData }) =>
    (dispatch) => {
      dispatch({
        type: types.SHOW_NOTIFICATION,
        payload: {
          messageText: message,
          messageType: 'error',
        },
      });
    },
};

// the default reducer
const reducer = (state = {}, action) => {
  switch (action.type) {
    case types.SHOW_NOTIFICATION:
      return {
        ...state,
        notificationVisible: true,
        notificationMessage: action.payload,
      };
    case types.HIDE_NOTIFICATION:
      return {
        ...state,
        notificationVisible: false,
        notificationMessage: '',
      };
    default:
      return state;
  }
};

export default reducer;
