import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import NavLinks from '../NavBar/NavLinks';
import SocialLinks from '../SocialLinks';
import './Footer.css';

const Footer = () => {
  const { pathname, hash } = useLocation();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const getIsActivePath = (linkToMatch, isExact = false) =>
    linkToMatch === pathname && ((isExact && !hash) || !isExact)
      ? 'footer__navbar__link--active'
      : '';
  return (
    <footer className="footer">
      <div>
        <NavLinks getIsActivePath={getIsActivePath} />
      </div>
      {!isAuthenticated && (
        <div>
          <button
            type="button"
            className="btn btn-link"
            onClick={loginWithRedirect}
          >
            Log in
          </button>
        </div>
      )}
      <div className="mb-5">
        <SocialLinks />
      </div>
      <p>&copy; Lou Bro Studio Mtl.</p>
    </footer>
  );
};

export default Footer;
