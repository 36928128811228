import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { getAuthUserId } from '../../utilities/authUtils';
import { actions as createUserActions } from '../../ducks/user/create';
import useTwitchUsernameFromAuth0 from '../../hooks/useTwitchUsernameFromAuth0';

const LogoutButton = () => {
  const dispatch = useDispatch();
  const { logout, user, getAccessTokenSilently } = useAuth0();
  const twitchUsername = useTwitchUsernameFromAuth0();

  useEffect(() => {
    try {
      const userId = getAuthUserId(user);
      const { name } = user;
      // this shit literally calling create user non stop, made a trello item for it.
      // should instead have some kind of logic to only call on first login
      // then have some other mechanism for checking if isLoggedIn pings less regularly
      // but yeah this is the only place on the frontend where we actually create a user
      // big ole todo item but if it aint broke dont fix it i guess...
      dispatch(
        createUserActions.load(
          { id: userId, name: name || twitchUsername },
          getAccessTokenSilently
        )
      );
    } catch (e) {
      // don't do anything because there's no problem
    }
  }, [dispatch, user, getAccessTokenSilently, twitchUsername]);

  return (
    <button
      className="btn btn-danger btn-block"
      type="button"
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
    >
      Log Out
    </button>
  );
};

export default LogoutButton;
