export const getModalState = (state) => state?.modal;

export const types = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
};

export const actions = {
  show: (content) => (dispatch) => {
    dispatch({
      type: types.SHOW_MODAL,
      payload: content,
    });
  },
  hide: () => (dispatch) => {
    dispatch({
      type: types.HIDE_MODAL,
    });
  },
};

// the default reducer
const reducer = (state = {}, action) => {
  switch (action.type) {
    case types.SHOW_MODAL:
      return {
        ...state,
        isVisible: true,
        content: action.payload,
      };
    case types.HIDE_MODAL:
      return {
        ...state,
        isVisible: false,
        content: null,
      };
    default:
      return state;
  }
};

export default reducer;
