import { combineReducers } from 'redux';
import getLatestReducer from './getLatest';
import updateLatestReducer from './updateLatest';

const reducer = combineReducers({
  latest: getLatestReducer,
  update: updateLatestReducer,
});

export default reducer;
