import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from '../../App';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
// ['create:users', 'read:users', 'update:users']
const Auth0ProviderWithHistory = () => (
  <BrowserRouter>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      scope="read:users"
    >
      <App />
    </Auth0Provider>
  </BrowserRouter>
);

export default Auth0ProviderWithHistory;
