import { useDispatch, useSelector } from 'react-redux';
import {
  actions as notificationActions,
  getNotificationState,
} from '../../ducks/notifications';
import './Notification.css';

const Notification = () => {
  const dispatch = useDispatch();
  const notificationState = useSelector((state) => getNotificationState(state));
  const { notificationVisible, notificationMessage } = notificationState;

  if (!notificationVisible) {
    return null;
  }

  return (
    <div
      className={`notification-panel notification-panel--${notificationMessage?.messageType}`}
    >
      <div className="notification">
        {notificationMessage?.messageType && (
          <h5 className="notification__message-type">
            {notificationMessage.messageType}
          </h5>
        )}
        <p>{notificationMessage?.messageText}</p>
      </div>
      <div className="notification__close-container">
        <button
          className="notification__close-button"
          type="button"
          onClick={() => dispatch(notificationActions.hide())}
        >
          Ⅹ
        </button>
      </div>
    </div>
  );
};

export default Notification;
