import { getActions, getReducers, getTypes } from '../initializers';

// user create state
const identifier = 'USER_CREATE';

const fetchOptions = (params) => {
  const { id, name } = params;
  return {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id,
      name,
    }),
  };
};

export const types = getTypes(identifier);
export const actions = getActions(
  identifier,
  (params) => `users/${params.id}`,
  (params) => fetchOptions(params)
);
const reducer = getReducers(identifier);

export default reducer;
