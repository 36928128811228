import { combineReducers } from 'redux';
import listGamesReducer from './listGames';
import listPlayersReducer from './listPlayers';

// the default export is the reducer, which shapes the state
const reducer = combineReducers({
  gamesList: listGamesReducer,
  playersList: listPlayersReducer,
});

export default reducer;
