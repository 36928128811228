import { getActions, getReducers, getTypes } from '../initializers';
import { actions as getUserActions, types as getUserTypes } from './get';

const identifier = 'USER_ADD_TROUT_COIN';

const fetchOptions = (params) => {
  const { id, name, messageData } = params;
  return {
    method: 'PUT',
    body: JSON.stringify({
      id,
      name,
      operation: 'addTroutCoins',
      messageData,
    }),
  };
};

export const types = getTypes(identifier);
export const actions = getActions(
  identifier,
  (params) => `users/addTroutCoins/${params.id}`,
  (params) => fetchOptions(params),
  undefined,
  (payload) => getUserActions.update(getUserTypes, payload)
);
const reducer = getReducers(identifier);

export default reducer;
