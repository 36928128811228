import { getActions, getReducers, getTypes } from '../initializers';

const identifier = 'CLIENT_VERSION_UPDATE';

export const getUpdateClientVersionState = (state) =>
  state.clientVersion?.update;

const fetchOptions = (body) => ({
  method: 'PUT',
  body,
});

export const types = getTypes(identifier);
export const actions = getActions(
  identifier,
  () => 'client-version/add-latest',
  (params) => fetchOptions(params)
);
const reducer = getReducers(identifier);

export default reducer;
