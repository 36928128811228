import { combineReducers } from 'redux';
import userCreateReducer from './create';
import userGetReducer from './get';
import userUpdateReducer from './update';
import userAddTroutCoinReducer from './addTroutCoin';
import userAddSkinReducer from './addSkin';
import userEquipSkinReducer from './equipSkin';

// the default export is the reducer, which shapes the state
const reducer = combineReducers({
  userCreate: userCreateReducer,
  userGet: userGetReducer,
  userUpdate: userUpdateReducer,
  userAddTroutCoin: userAddTroutCoinReducer,
  userAddSkin: userAddSkinReducer,
  userEquipSkin: userEquipSkinReducer,
});

export default reducer;
