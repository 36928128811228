import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModalState, actions as modalActions } from '../../ducks/modal';
import './Modal.css';
import useOnClickOutside from '../../hooks/useOnClickOutside';

const Modal = () => {
  const dispatch = useDispatch();
  const { isVisible, content } = useSelector((state) => getModalState(state));

  const hideModal = () => {
    dispatch(modalActions.hide());
  };

  // hide the modal on click outside
  const modalRef = useOnClickOutside(hideModal);

  // prevent body scroll when modal is visible. fire upon isVisible change.
  useEffect(() => {
    const bodyOverflow = isVisible ? 'hidden' : 'unset';
    document.body.style.overflow = bodyOverflow;
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal__inner" ref={modalRef}>
        <div className="modal__content">{content}</div>
        <div className="modal__close-container">
          <button
            type="button"
            className="close text-light"
            aria-label="Close"
            onClick={hideModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
