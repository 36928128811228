import { getActions, getReducers, getTypes } from '../initializers';

const identifier = 'SKINS_LIST';

export const getSkinsListState = (state) => state.skins?.list;

export const types = getTypes(identifier);
export const actions = getActions(identifier, () => 'skins');
const reducer = getReducers(identifier);

export default reducer;
