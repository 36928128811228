import { getActions, getReducers, getTypes } from '../initializers';

// user get state
const identifier = 'USER_GET';

export const getUserGetState = (state) => state.user?.userGet;

export const types = getTypes(identifier);
export const actions = getActions(identifier, (params) => `users/${params.id}`);
const reducer = getReducers(identifier);

export default reducer;
