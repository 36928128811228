import FishIcon from '../Svg/FishIcon';
import './Troutcoin.css';

const borderOffset = 16;

const Troutcoin = ({ isSpinning = true, size = 24 }) => (
  <div className="troutcoin__container">
    <div
      className={`troutcoin ${isSpinning ? 'troutcoin--spinning' : ''}`}
      style={{
        width: `${size + borderOffset}px`,
        height: `${size + borderOffset}px`,
      }}
    >
      <FishIcon size={size} />
    </div>
  </div>
);

export default Troutcoin;
