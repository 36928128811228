import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'videoState',
  initialState: { hidden: false, ended: false },
  reducers: {
    setEnded: (state, { payload }) => {
      // set the cookie to say they watched this mufk
      document.cookie = 'splashVideoWatched=1;';
      // eslint-disable-next-line no-param-reassign
      state.ended = payload;
    },
    setHidden: (state, { payload }) => {
      // eslint-disable-next-line no-param-reassign
      state.hidden = payload;
    },
  },
});

export const selectVideoState = (state) => state.videoState;

export const { setEnded, setHidden } = slice.actions;

export default slice.reducer;
