import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import NavLinks from './NavLinks';
import AuthNav from '../auth/AuthNav';
import Hamburger from './Hamburger';
import NavBarProfile from './NavBarProfile';
import './NavBar.css';

const NavBar = () => {
  const { pathname, hash, search } = useLocation();

  const [isShowingMobileNav, setIsShowingMobile] = useState(false);
  const getIsActivePath = (linkToMatch, isExact = false) =>
    linkToMatch === pathname && ((isExact && !hash) || !isExact)
      ? 'navbar__link--active'
      : '';

  useEffect(() => {
    // if u change location it should hide the nav if it's active
    setIsShowingMobile(false);
    // also set a pageview.
    const pagePath = `${pathname || ''}${search || ''}`;
    ReactGA.send({ hitType: 'pageview', page: pagePath });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, pathname]);

  return (
    <div className="navbar-container">
      <Hamburger
        onClick={() => setIsShowingMobile(!isShowingMobileNav)}
        isActive={isShowingMobileNav}
      />
      <nav className={`navbar ${isShowingMobileNav ? 'navbar--visible' : ''}`}>
        <NavLinks getIsActivePath={getIsActivePath} />
        <NavBarProfile />
        <AuthNav />
      </nav>
    </div>
  );
};

export default NavBar;
